
import { IAdmin, IAdminUpdate, ILogin } from '@/interfaces'
import { MUTATIONS, store } from '@/store'
import moment from 'moment'
import { fakeAdminList, fakeLogin, fakeStatistics } from '../utils/FakeApi'
import NetworkService, { Endpoint } from './NetworkService'

export default class UserService {
  network
  constructor() {
    this.network = new NetworkService()
  }
  listAdmins = (params: any) => {
    return this.network.get(Endpoint.users, { ...params, isAdmin: true })
  }
  createAdmin = (params: IAdmin) => {
    return this.network.post(Endpoint.usersAdmin, params)
  }
  list = (params: any) => {
    return this.network.get(Endpoint.users, params)
  }
  show = (id: string) => {
    return this.network.get(`${Endpoint.users}/${id}`)
  }
  delete = (id: string) => {
    return this.network.delete(`${Endpoint.users}/${id}`)
  }
  update = (id: string, params: IAdminUpdate) => {
    return this.network.put(`${Endpoint.users}/${id}`, params)
  }
  login = async (params: ILogin) => {
    const res = await this.network.post(Endpoint.login, params)
    if (res.data.isAdmin) {
      return res
    } else {
      throw ({ status: false, message: 'Credenciais inválidas' })
    }
  }
  logout = () => {
    store.commit(MUTATIONS.CLEAR_SESSION)
  }
  getFormattedUser(data: any) {
    data.formatted_created_at = moment(data.created_at).format('DD/MM/YYYY')
    return data
  }
}