import { store } from '@/store'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import paths from './paths'
const routes: Array<RouteRecordRaw> = [

  {
    path: paths.login,
    name: 'Login',
    component: () => import('@/views/Login/Login.vue'),
    props: true,
  },



  // SIMULADOS
  {
    path: paths.mockExams.list,
    name: 'MockExams',
    alias: '/',
    component: () => import('@/views/MockExams/MockExams.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: paths.mockExams.add,
    name: 'AddMockExams',
    component: () => import('@/views/MockExams/AddMockExams/AddMockExams.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.mockExams.detail}/:id`,
    name: 'MockExamsDetail',
    component: () => import('@/views/MockExams/MockExamDetail/MockExamDetail.vue'),
    props: true,
    meta: { requiresAuth: true }
  },

  // USERS
  {
    path: paths.users.list,
    name: 'Users',
    component: () => import('@/views/Users/Users.vue'),
    props: true,
    meta: { requiresAuth: true }

  },
  {
    path: `${paths.users.detail}/:id`,
    name: 'UserDetail',
    component: () => import('@/views/Users/UserDetail/UserDetail.vue'),
    props: true,
    meta: { requiresAuth: true }

  },
  // RECURSOS
  {
    path: paths.appeals.list,
    name: 'Appeals',
    component: () => import('@/views/Appeals/Appeals.vue'),
    meta: { requiresAuth: true }

  },
  {
    path: `${paths.appeals.detail}/:id`,
    name: 'AppealDetail',
    component: () => import('@/views/Appeals/AppealDetail/AppealDetail.vue'),
    props: true,
    meta: { requiresAuth: true }

  },

  // PEDIDOS

  {
    path: paths.orders.list,
    name: 'Orders',
    component: () => import('@/views/Orders/Orders.vue'),
    props: true,
    meta: { requiresAuth: true }

  },
  {
    path: `${paths.orders.detail}/:id`,
    name: 'OrderDetail',
    component: () => import('@/views/Orders/OrderDetail/OrderDetail.vue'),
    props: true,
    meta: { requiresAuth: true }

  },


  // APROVADOS
  {
    path: paths.approved.list,
    name: 'Approved',
    component: () => import('@/views/Approved/Approved.vue'),
    meta: { requiresAuth: true }
  },


  // DEPOIMENTOS
  {
    path: paths.testimonies.list,
    name: 'Testimonies',
    component: () => import('@/views/Testimonies/Testimonies.vue'),
    meta: { requiresAuth: true }
  },
  // ADD DEPOIMENTOS
  {
    path: paths.testimonies.add,
    name: 'Add TestimonyAdd',
    component: () => import('@/views/Testimonies/TestimonyAdd/TestimonyAdd.vue'),
    meta: { requiresAuth: true }
  },
  // EDIT DEPOIMENTOS
  {
    path: `${paths.testimonies.edit}/:id`,
    name: 'Add TestimonyEdit',
    props: true,
    component: () => import('@/views/Testimonies/TestimonyAdd/TestimonyAdd.vue'),
    meta: { requiresAuth: true }
  },




  // BANNERS
  {
    path: paths.banners.list,
    name: 'Banners',
    component: () => import('@/views/Banners/Banners.vue'),
    meta: { requiresAuth: true }

  },
  {
    path: `${paths.banners.detail}/:id`,
    name: 'BannerDetail',
    component: () => import('@/views/Banners/BannerDetail/BannerDetail.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: paths.banners.add,
    name: 'BannerAdd',
    component: () => import('@/views/Banners/BannerAdd/BannerAdd.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.banners.edit}/:id`,
    name: 'BannerEdit',
    component: () => import('@/views/Banners/BannerAdd/BannerAdd.vue'),
    props: true,
    meta: { requiresAuth: true }
  },


  // TURMAS
  {
    path: paths.groups.list,
    name: 'Groups',
    component: () => import('@/views/Groups/Groups.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: paths.groups.add,
    name: 'GroupAdd',
    component: () => import('@/views/Groups/GroupAdd/GroupAdd.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.groups.detail}/:id`,
    name: 'GroupDetail',
    props: true,
    component: () => import('@/views/Groups/GroupDetail/GroupDetail.vue'),
    meta: { requiresAuth: true }
  },

  // ADMINS
  {
    path: paths.admins.list,
    name: 'Admins',
    component: () => import('@/views/Admins/Admins.vue'),
    props: true,
    meta: { requiresAuth: true }

  },
  {
    path: `${paths.admins.detail}/:id`,
    name: 'AdminDetail',
    component: () => import('@/views/Admins/AdminDetail/AdminDetail.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: paths.admins.add,
    name: 'AdminAdd',
    component: () => import('@/views/Admins/AddAdmins/AddAdmins.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.admins.edit}/:id`,
    name: 'AdminEdit',
    props: true,
    component: () => import('@/views/Admins/AddAdmins/AddAdmins.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.admins.password_update}/:id`,
    name: 'AdminsPasswordUpdate',
    props: true,
    component: () => import('@/views/Admins/AdminPasswordUpdate/AdminPasswordUpdate.vue'),
    meta: { requiresAuth: true }
  },
  // EDIT CONFIGURAÇÕES

  {
    path: `${paths.settings.edit}/:settings_key`,
    name: "Editar Configurações",
    component: () => import('@/views/Settings/SettingsEdit/SettingsEdit.vue'),
    props: true,
    meta: { requiresAuth: true }
  },

  // VIEW CONFIGURAÇÕES
  {
    path: `${paths.settings.view}/:settings_key`,
    name: "Visualizar Configurações",
    component: () => import('@/views/Settings/Settings.vue'),
    props: true,
  },
  // Campos selecionaveis
  {
    path: `${paths.categories}`,
    name: "Campos selecionaveis",
    component: () => import('@/views/Categories/Categories.vue'),
  },
  {
    path: paths.not_found,
    name: 'NotFound',
    component: () => import('@/views/NotFound/NotFound.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      name: 'NotFound'
    }
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = !!store.state.session;
  if (to.meta.requiresAuth && !isLoggedIn) {
    next(paths.login);
  } else {
    next();
  }
});

export default router
