<template>
  <nav class="navbar navbar-vertical fixed-start navbar-expand-md navbar-dark"
    :class="{ 'navbar-black': userSession?.type === 'master' }" id="sidebar">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse"
        aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <router-link :to="$paths.home" class="navbar-brand text-start d-flex align-items-center">
        <img class="navbar-brand-img" src="@/assets/img/sidebar_logo.png" :alt="appName" />
        <span style="font-size: 0.7rem" class="text-muted ms-3">
          {{ appName }}
        </span>
      </router-link>

      <div class="navbar-user d-md-none">
        <div class="dropdown">
          <a href="#" id="sidebarIcon" class="dropdown-toggle" role="button" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <div class="avatar avatar-sm">
              <i class="fas fa-user-circle display-4 text-light"></i>
            </div>
          </a>

          <div class="dropdown-menu dropdown-menu-end" aria-labelledby="sidebarIcon">
            <a href="#" class="dropdown-item">Profile</a>
            <hr class="dropdown-divider" />
            <a href="#" class="dropdown-item">Logout</a>
          </div>
        </div>
      </div>

      <div class="collapse navbar-collapse" id="sidebarCollapse">
        <template v-for="item in screens">
          <hr class="navbar-divider" />
          <h6 class="navbar-heading mb-0">{{item.header}}</h6>
          <ul class="navbar-nav">
            <router-link active-class="router-link-active" :to="path.to" v-for="path in item.paths">
              <li class="nav-item">
                <a class="nav-link">
                  <i :class="`sidebar-icon ${path.icon}`"></i>
                  {{path.name}}
                </a>
              </li>
            </router-link>
            </ul>
        </template>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  computed: {
    userSession() {
      return this.$store.state.userSession
    },
    chatBadge() {
      const userInfo = this.$store.state.userInfo
      return userInfo?.chatBadge || userInfo?.appointmentBadge
    }
  },
  mounted() {
    this.isAdmin = this.userSession?.type == 'admin'
  },
  data() {
    return {
      screens: [
        {
          header: 'Cadastros',
          paths: [
            {
              name: 'Simulados',
              to: this.$paths.mockExams.list,
              icon: 'fas fa-list-check'
            },
            {
              name: 'Turmas',
              to: this.$paths.groups.list,
              icon: 'fas fa-layer-group'
            },
            {
              name: 'Pedidos',
              to: this.$paths.orders.list,
              icon: 'fas fa-receipt'
            },
            {
              name: 'Recursos',
              to: this.$paths.appeals.list,
              icon: 'fas fa-flag'
            },
            {
              name: 'Usuários',
              to: this.$paths.users.list,
              icon: 'fas fa-users'
            },
            {
              name: 'Banners',
              to: this.$paths.banners.list,
              icon: 'fas fa-images'
            },
            {
              name: 'Campos selecionáveis',
              to: this.$paths.categories,
              icon: 'fas fa-hand-pointer'
            },
            {
              name: 'Administradores',
              to: this.$paths.admins.list,
              icon: 'fas fa-user-cog'
            },
          ]
        },
        {
          header: 'Tela de aprovados',
          paths: [
		  	{
              name: 'Aprovados',
              to: this.$paths.approved.list,
              icon: 'fas fa-medal'
            },
            {
              name: 'Depoimentos',
              to: this.$paths.testimonies.list,
              icon: 'fas fa-comment-dots'
            },
          ]
        },
        {
          header: 'Contratos',
          paths: [
            {
              name: 'Contratos & Termos',
              to: this.$paths.settings.terms.edit,
              icon: 'fas fa-cog'
            },
          ]
        }
      ],
      isAdmin: false,
      appName: `${process.env.VUE_APP_APP_NAME.toUpperCase()} ${process.env.VUE_APP_ENV_STRING} | CMS`
    }
  }
}
</script>

<style scoped>
.sidebar-icon {
  width: 25px;
  text-align: left;
}
</style>
