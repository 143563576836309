
import { IMockExamPost, ISelectOption } from '@/interfaces'
import moment from 'moment'
import NetworkService, { Endpoint } from './NetworkService'
import { EMockExamType } from '@/enums'
import { prepareYoutubeLink } from '@/components/YoutubeInput/YoutubeInputController'
import ICommentPost from '@/interfaces/ICommentPost'
import IMockExamUpdate from '@/interfaces/IMockExamUpdate'

export default class ExamService {
  network
  constructor() {
    this.network = new NetworkService()
  }
  static MINIMUM_EXAM_PER_GROUP = 2
  static modalityOptions: ISelectOption[] = [
    {
      id: 'Adaptado',
      title: 'Adaptado',
    },
    {
      id: 'Inédito',
      title: 'Inédito',
    },
    {
      id: 'Provas anteriores',
      title: 'Provas anteriores',
    },
  ]
  makeAlternatives = (alternativesCount: number, examType: EMockExamType) => {
    let answers = []
    const startIndex = 65
    // eu somo 65 + qtd de alternativas e vejo até qual letra vai ter
    const lastIndex = startIndex + alternativesCount
    // no loop eu converto o codigo pra letra e add no array
    // não, eu nao usei o Gepeto 😎
    for (let index = startIndex; index < lastIndex; index++) {
      if (index < 90) {
        answers.push(String.fromCharCode(index))
      }
    }
    return examType == EMockExamType.multipe ? answers : ['C', 'E']
  }
  list = (params: any) => {
    return this.network.get(Endpoint.mockExams, params)
  }
  show = async (id: string) => {
    let res = await this.network.get(`${Endpoint.mockExams}/${id}`)
    const commentRes = await this.network.get(`${Endpoint.testimony}?simulation_id=${id}`)
    res.data.comments = commentRes.data
    return res
  }
  create = (params: IMockExamPost) => {
    return this.network.postMultipart(`${Endpoint.mockExams}`, params)
  }
  createExamFiles = (simulation_id: string, files: File[]) => {
    return this.network.postMultipart(`${Endpoint.addExamPdf}`, { simulation_id, exam_pdf: files })
  }
  deleteExamFile = (id: string | number) => {
    return this.network.delete(`${Endpoint.deleteExamPdf}?id=${id}`)
  }
  createResolutionFiles = (simulation_id: string, files: File[]) => {
    return this.network.postMultipart(`${Endpoint.addResolutionPdf}`, { simulation_id, resolution_pdf: files })
  }
  deleteResolutionFile = (id: string | number) => {
    return this.network.delete(`${Endpoint.deleteResolutionPdf}?id=${id}`)
  }
  createComment = (params: ICommentPost) => {
    return this.network.postMultipart(`${Endpoint.testimony}`, params)
  }
  deleteComment = (id: string | number) => {
    return this.network.delete(`${Endpoint.testimony}/${id}`)
  }
  update = (id: number | string, params: Partial<IMockExamUpdate>) => {
    return this.network.postMultipart(`${Endpoint.updateExam}/${id}`, params)
  }
  annulQuestion = (question_number: number | string, simulation_id: number | string) => {
    return this.network.post(Endpoint.annulQuestion, { simulation_id, question_number })
  }
  getFormattedComment(comment: any) {
    try {
      comment.formatted_created_at = moment(comment.created_at).format('DD/MM/YYYY - HH[h]mm')
    } catch (e) {
      console.log(e)
    } finally {
      return comment
    }
  }
  getFormattedExam = (item: any) => {
    try {
      item.formatted_image = item.image || require('@/assets/img/image_placeholder.png')
      item.formatted_exam_datetime = moment(item.exam_datetime).format('DD/MM/YYYY - HH[h]mm')
      item.formatted_duration = new Date(item.duration * 1000).toISOString().substr(11, 5).replace(':', 'h')
      item.formatted_status = item.active ? 'Ativo' : 'Inativo'
      item.formatted_type = item.exam_type == EMockExamType.truefalse ? 'Certo ou errado' : 'Múltipla escolha'
      item.formatted_introduction_video = item.introduction_video ? (prepareYoutubeLink(item.introduction_video) || '') : ''
      item.formatted_resolution_video = item.resolution_video ? prepareYoutubeLink(item.resolution_video) : ''
      item.formatted_price = !item.price ? 'Gratuíto' : item.price.toCurrency()
      item.exam_pdf = item.exam_pdf.map((elem: any) => {
        return {
          ...elem,
          name: this.getFormattedFileName(elem)
        }
      })
      item.resolution_pdf = item.resolution_pdf?.map((elem: any) => {
        return {
          ...elem,
          name: this.getFormattedFileName(elem)
        }
      })
      if (item.comments) {
        item.comments = item.comments.map((com: any) => {
          return this.getFormattedComment(com)
        })
      }
    } catch (e) {
      console.log(e)
    } finally {
      return item
    }
  }

  getFormattedFileName(file: any) {
    try {
      const name = file.url.split('/')
      return name[name.length - 1]
    } catch (e) {
      console.log(e)
      return ''
    }
  }
}
