
import { IBannerPost } from '@/interfaces'
import NetworkService, { Endpoint } from './NetworkService'
import IBannerUpdate from '@/interfaces/IBannerUpdate'

export enum ECategorySelectType {
  category = 'categories',
  question_category = 'question_category',
  exam_board = 'exam_board',
  institute = 'institute',
  job_title = 'job_title',
  level = 'level',
}

export default class CategorySevice {
  network
  constructor() {
    this.network = new NetworkService()
  }

  update = (type: ECategorySelectType, id: string | number, name: string) => {
    switch (type) {
      case ECategorySelectType.question_category:
        return this.network.put(`${Endpoint.questionCategories}/${id}`, { name })
      case ECategorySelectType.exam_board:
        return this.network.put(`${Endpoint.boards}/${id}`, { title: name })
      case ECategorySelectType.institute:
        return this.network.put(`${Endpoint.institutes}/${id}`, { title: name })
      case ECategorySelectType.job_title:
        return this.network.put(`${Endpoint.jobTitles}/${id}`, { title: name })
      case ECategorySelectType.level:
        return this.network.put(`${Endpoint.levels}/${id}`, { title: name })
      default:
        throw ('Não é possivel editar este item ou ele nao é do tipo ECategorySelectType')
    }
  }
  delete = (type: ECategorySelectType, id: string) => {
    switch (type) {
      case ECategorySelectType.question_category:
        return this.network.delete(`${Endpoint.questionCategories}/${id}`)
      case ECategorySelectType.exam_board:
        return this.network.delete(`${Endpoint.boards}/${id}`)
      case ECategorySelectType.institute:
        return this.network.delete(`${Endpoint.institutes}/${id}`)
      case ECategorySelectType.job_title:
        return this.network.delete(`${Endpoint.jobTitles}/${id}`)
      case ECategorySelectType.level:
        return this.network.delete(`${Endpoint.levels}/${id}`)
      default:
        throw ('Não é possivel editar este item ou ele nao é do tipo ECategorySelectType')
    }
  }

  async listExamSelectOptions() {
    const res = await this.network.get(Endpoint.allCategories)
    return Promise.resolve({
      status: true,
      data: {
        [`${ECategorySelectType.category}`]: res.data.simulation_category.map((elem: any) => {
          return {
            id: elem,
            title: elem
          }
        }),
        [`${ECategorySelectType.question_category}`]: res.data.question_category.map((elem: any) => {
          return {
            id: elem.id,
            title: elem.name
          }
        }),
        [`${ECategorySelectType.exam_board}`]: res.data.bench,
        [`${ECategorySelectType.institute}`]: res.data.institutes,
        [`${ECategorySelectType.level}`]: res.data.level,
        [`${ECategorySelectType.job_title}`]: res.data.offices,
      }
    })
  }

  async create(type: ECategorySelectType, name: string) {
    let res: any
    try {
      switch (type) {
        case ECategorySelectType.question_category:
          res = await this.network.post(Endpoint.questionCategories, { name })
          return {
            id: res.data.id,
            title: res.data.name,
          }
        case ECategorySelectType.exam_board:
          res = await this.network.post(Endpoint.boards, { title: name })
          return {
            id: res.data.id,
            title: res.data.title,
          }

        case ECategorySelectType.institute:
          res = await this.network.post(Endpoint.institutes, { title: name })
          return {
            id: res.data.id,
            title: res.data.title,
          }
        case ECategorySelectType.job_title:
          res = await this.network.post(Endpoint.jobTitles, { title: name })
          return {
            id: res.data.id,
            title: res.data.title,
          }
        case ECategorySelectType.level:
          res = await this.network.post(Endpoint.levels, { title: name })
          return {
            id: res.data.id,
            title: res.data.title,
          }
        default:
          return {
            id: '',
            title: '',
          }
      }
    } catch (e) {
      console.log(e)
      return []
    }
  }
}
