<template>
  <div class="container mt-5" style="max-width: 350px;">
    <div class="row justify-content-center">
      <div class="col-12 my-5">
        <div class="text-center text-muted">
          <i class="fa-solid fa-2x fa-magnifying-glass"></i>
          <h5 class=" my-3 h4 mb-1">
            Nenhum item encontrado
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SimpleEmptystate',
}
</script>
