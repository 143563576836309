
import { IAdmin, IAdminUpdate, ILogin } from '@/interfaces'
import { MUTATIONS, store } from '@/store'
import moment from 'moment'
import { fakeAdminList, fakeLogin, fakeStatistics } from '../utils/FakeApi'
import NetworkService, { Endpoint } from './NetworkService'

export enum EAppealStatus {
  pending = 'pending',
  rejected = 'rejected',
  approved = 'approved'
}

export default class AppealService {
  network
  constructor() {
    this.network = new NetworkService()
  }
  list = (params: any) => {
    return this.network.get(Endpoint.appeals, params)
  }
  show = (id: string) => {
    return this.network.get(`${Endpoint.appeals}/${id}`)
  }
  // delete = (id: string) => {
  //   return this.network.delete(`${Endpoint.users}/${id}`)
  // }
  update = (id: string, params: any) => {
    return this.network.put(`${Endpoint.appeals}/${id}`, params)
  }
  login = async (params: ILogin) => {
    const res = await this.network.post(Endpoint.login, params)
    if (res.data.isAdmin) {
      return res
    } else {
      throw ({ status: false, message: 'Credenciais inválidas' })
    }
  }
  logout = () => {
    store.commit(MUTATIONS.CLEAR_SESSION)
  }
  getStatus(st: EAppealStatus) {
    switch (st) {
      case EAppealStatus.approved:
        return {
          title: 'Recurso aceito',
          icon: 'fas fa-check-circle',
          text: 'text-success',
        }
      case EAppealStatus.rejected:
        return {
          title: 'Recurso negado',
          icon: 'fas fa-times-circle',
          text: 'text-muted',
        }
      case EAppealStatus.pending:
        return {
          title: 'Aguardando avaliação',
          icon: 'fas fa-clock',
          text: 'text-warning',
        }
      default:
        return {
          title: 'Sem status'
        }
    }

  }
  getFormattedAppeal(data: any) {
    data.formatted_created_at = moment(data.created_at).format('DD/MM/YYYY')
    data.status_object = this.getStatus(data.status)
    return data
  }
}