
import NetworkService, { Endpoint } from './NetworkService'

export enum ESettingsKey {
  terms = 'terms_of_use'
}

export default class SettingsService {
  network
  constructor() {
    this.network = new NetworkService()
  }
  show = (key: ESettingsKey) => {
    return this.network.get(`${Endpoint.settings}/${key}`);
  };
  update = (key: ESettingsKey, value: string) => {
    return this.network.put(`${Endpoint.settings}/${key}`, { value });
  };
}