
import { IBannerPost } from '@/interfaces'
import NetworkService, { Endpoint } from './NetworkService'
import IBannerUpdate from '@/interfaces/IBannerUpdate'

export default class ApprovedService {
  network
  constructor() {
    this.network = new NetworkService()
  }
  list = (params: any) => {
    return this.network.get(Endpoint.approved, params)
  }
  create = (image: File) => {
    return this.network.postMultipart(`${Endpoint.approved}`, { image })
  }
  delete = (id: string) => {
    return this.network.delete(`${Endpoint.approved}/${id}`)
  }
}
