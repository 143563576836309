
import moment from 'moment'
import NetworkService, { Endpoint } from './NetworkService'
import IGeneralCommentPost from '@/interfaces/IGeneralCommentPost'

export default class TestimonyService {
  network
  constructor() {
    this.network = new NetworkService()
  }
  create = (params: IGeneralCommentPost) => {
    return this.network.postMultipart(Endpoint.generalTestimonials, params)
  }
  list = (params: any) => {
    return this.network.get(Endpoint.generalTestimonials, params)
  }
  show = (id: string) => {
    return this.network.get(`${Endpoint.generalTestimonials}/${id}`)
  }
  delete = (id: string) => {
    return this.network.delete(`${Endpoint.generalTestimonials}/${id}`)
  }
  update = (id: string, params: IGeneralCommentPost) => {
    return this.network.postMultipart(`${Endpoint.generalTestimonialsUpdate}/${id}`, params)
  }
}
