<template>
  <div>
    <div class="d-flex justify-content-between">
      <label v-if="label" class="form-label text-muted"> {{ label }} <i v-if="required" class="text-danger">*</i>
      </label>
      <slot name="labelContentEnd"></slot>
    </div>
    <div class="autocomplete-input-container position-relative">
      <input class="form-control" type="text" :placeholder="placeholder" :style="inputStyle" v-model.trim="searchString"
        @focus="() => { showResults = true; handleSearch() }" @focusout="hideResults" @input="handleSearch"
        :class="{ 'is-invalid': validationModel?.$invalid || false }" />
      <div class="invalid-feedback">
        <small>{{ errorMsg }}</small>
      </div>
      <div v-if="showResults" class="bg-white position-absolute select-custom w-100" style="z-index: 10">
        <ul class="list-group rounded-bottom" id="results-list">
          <li class="list-group-item text-muted cursor-pointer" @click="openNewItemModal">
            <span class="fas fa-plus-circle text-primary me-2"></span>Criar um novo item
          </li>
          <li class="list-group-item cursor-pointer" v-for="(result) in filteredResults"
            @click="handleItemSelected(result)">
            {{ result.title }}
          </li>
        </ul>
      </div>
    </div>
    <slot name="content"></slot>
    <ModalComponent :title="'Novo item'" :id="modalId">
      <template v-slot:body>
        <label class="form-label text-muted"> Informe o título do novo item <i class="text-danger">*</i> </label>
        <input class="form-control" v-model.trim="newItemTitle" type="text" :maxlength="50"
          placeholder="Informe o título do novo item" />
      </template>
      <template v-slot:footer>
        <FormButton label="Concluir" :loading="submittingNewItem" @onClick="handleCreateNewItem" />
      </template>
    </ModalComponent>
  </div>
</template>

<script>
import AutocompleteSelectInputController from './AutocompleteSelectInputController'
export default AutocompleteSelectInputController
</script>
<style>
.select-custom .list-group {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.select-custom .list-group-item {
  padding: 12px 20px;
  border-color: #d9d9d9;
}

.select-custom .list-group-item:hover {
  background-color: #dee6e1;
}

.no-border {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
</style>
