
import moment from 'moment'
import NetworkService, { Endpoint } from './NetworkService'

export default class OrderService {
  network
  constructor() {
    this.network = new NetworkService()
  }
  list = (params: any) => {
    return this.network.get(Endpoint.simulationOrders, params)
  }
  show = (id: string) => {
    return this.network.get(`${Endpoint.simulationOrders}/${id}`)
  }
  prepareExam = (data: any) => {
    try {
      data.formatted_created_at = moment(data.created_at).format('DD/MM/YYYY')
      data.formatted_status = data.status.capitalizeFirst()
      if (data.payment_method) {
        data.formatted_payment_method = data.payment_method = 'credit_card' ? 'Cartão de crédito' : 'Pix'
      }
    } catch (e) {
      console.log(e)
    } finally {
      return data
    }
  }

}
