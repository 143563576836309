
import { IBannerPost } from '@/interfaces'
import NetworkService, { Endpoint } from './NetworkService'
import IBannerUpdate from '@/interfaces/IBannerUpdate'

export default class BannerService {
  network
  constructor() {
    this.network = new NetworkService()
  }
  list = (params: any) => {
    return this.network.get(Endpoint.banners, params)
  }
  show = (id: string) => {
    return this.network.get(`${Endpoint.banners}/${id}`)
  }
  create = (params: IBannerPost) => {
    return this.network.postMultipart(`${Endpoint.banners}`, params)
  }
  update = (id: string | number, params: IBannerUpdate) => {
    console.log(params)
    return this.network.postMultipart(`${Endpoint.bannersUpdate}${id}`, params)
  }
  delete = (id: string) => {
    return this.network.delete(`${Endpoint.banners}/${id}`)
  }
}
