const paths = {
    not_found: '/not-found',
    login: '/login',
    home: '/simulados',
    categories: '/campos-selecionaveis',
    admins: {
        list: '/administradores',
        add: '/administradores/adicionar',
        edit: '/administradores/editar',
        detail: '/administradores/detalhes',
        password_update: '/editar-senha/administradores',
    },
    users: {
        list: '/usuarios',
        detail: '/usuarios/detalhes',
    },
    testimonies: {
        list: '/depoimentos',
        add: '/depoimentos/adicionar',
        edit: '/depoimentos/editar',
    },
    approved: {
        list: '/aprovados',
    },
    orders: {
        list: '/pedidos',
        detail: '/pedidos/detalhes',
    },
    mockExams: {
        list: '/simulados',
        detail: '/simulados/detalhes',
        add: '/simulados/adicionar',
        edit: '/simulados/editar',
    },
    banners: {
        list: '/banners',
        detail: '/banners/detalhes',
        add: '/banners/adicionar',
        edit: '/banners/editar',
    },
    groups: {
        list: '/turmas',
        detail: '/turmas/detalhes',
        add: '/turmas/adicionar',
        edit: '/turmas/editar',
    },
    appeals: {
        list: '/recursos',
        detail: '/recursos/detalhes',
    },
    settings: {
        edit: "/configuracoes/editar",
        view: "/configuracoes",
        terms: {
            edit: "/configuracoes/editar/termos-de-uso",
            view: "/configuracoes/termos-de-uso",
        },
    },
}

export default paths