import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalComponent = _resolveComponent("ModalComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ModalComponent, {
      title: _ctx.title === null || _ctx.title === undefined ? 'Confirmar ação' : _ctx.title,
      id: "confirmAlertModal",
      onModalClosed: _ctx.alertClosed
    }, {
      body: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.message === null || _ctx.message === undefined ? 'Tem certeza que deseja continuar ?' : _ctx.message), 1)
      ]),
      footer: _withCtx(() => [
        _createElementVNode("button", {
          type: "button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.cancelAction && _ctx.cancelAction(...args))),
          class: "btn btn-outline-primary w-15"
        }, " Não "),
        _createElementVNode("button", {
          type: "button",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.confirmAction && _ctx.confirmAction(...args))),
          class: "btn btn-primary w-15"
        }, " Sim ")
      ]),
      _: 1
    }, 8, ["title", "onModalClosed"])
  ]))
}