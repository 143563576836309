import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuillEditor = _resolveComponent("QuillEditor")!

  return (_openBlock(), _createBlock(_component_QuillEditor, {
    theme: "snow",
    content: _ctx.model.$model,
    "onUpdate:content": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.$model) = $event)),
    contentType: "html"
  }, null, 8, ["content"]))
}